import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text"

import Head from "../components/Head/Head";
import Layout from "../components/Layout/Layout";

import AboutStyles from "./about.module.scss";

const About = ({ data, location }) => {
  const { pageDetails } = data;

  const headshot = getImage(pageDetails.headshot)

  return (
    <Layout>
      <Head metaContent={pageDetails.metaContent} location={location.pathname} />
      <section className={AboutStyles.mainContent}>
        <div className={AboutStyles.textWrapper}>
          <h1>{pageDetails.pageHeader}</h1>
          {renderRichText(pageDetails.bio)}
        </div>
        <GatsbyImage
          className={AboutStyles.headshot}
          image={headshot}
          alt={pageDetails.headshot.description}
        />
      </section>
    </Layout>
  );
};

export default About;

export const query = graphql`
  query {
    pageDetails: contentfulAboutPage(pageHeader: { eq: "About" }) {
      metaContent {
        title
        description {
          description
        }
        image {
          file {
            url
          }
        }
      }
      pageHeader
      headshot {
        description
        gatsbyImageData
      }
      pageContent {
        pageContent
      }
      bio {
        raw
      }
    }
  }
`;
